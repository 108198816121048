body {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: url("/img/login-background.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }

.container {
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
  align-self: center;
  background-color: #171735;
  padding: 50px 30px;
  border-radius: 30px;
  text-align: center;
  width: 500px;
  min-width: 322px; }

.logo-wrapper {
  border-bottom: 1px solid #2e2e6a;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  padding-bottom: 20px; }

#content {
  font-weight: 300;
  font-size: 14pt;
  font-family: sans-serif;
  color: #a6a6d8;
  text-align: left;
  padding: 0  30px; }
